.colorX {
  color: transparent;
}
.white {
  color: #ffffff;
}
.greyF2 {
  color: #f2f3f5;
}
.greyF5 {
  color: #f5f5f5;
}
.greyE6 {
  color: #e6e6e6;
}
.greyBB {
  color: #b0b7c0;
}
.grey9D {
  color: #9d9d9d;
}
.grey74 {
  color: #747474;
}
.grey59 {
  color: #595959;
}
.black {
  color: #202020;
}
.yellow {
  color: #ffd74d;
}
.redE9 {
  color: #e92813;
}
.redFF {
  color: #ff6262;
}
.blue7D {
  color: #7d7af7;
}
.green {
  color: #22ab99;
}
.purp52 {
  color: #5240a0;
}
.violet {
  color: #6739ff;
}
.purple {
  color: #6739ff;
}
.sky027 {
  color: #027fff;
}
