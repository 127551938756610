.setMargin-L12 {
  margin-left: 1.2rem;
}
.setMargin-L24 {
  margin-left: 2.4rem;
}
.setMargin-L36 {
  margin-left: 3.6rem;
}
.setMargin-T17 {
  margin-top: 1.7rem;
}
.setMargin-T32 {
  margin-top: 3.2rem;
}
.setMargin-B16 {
  margin-bottom: 1.6rem;
}
