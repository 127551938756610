.w300 {
  font-weight: 300;
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.s12_17 {
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.s12_18 {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.s14_14 {
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.s14_18 {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.s16_24 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.s18_24 {
  font-size: 1.8rem;
  line-height: 2.4rem;
}


.textFamily {
  font-family: "Pretendard", sans-serif;
}
.textC {
  text-align: center;
}
.textL {
  text-align: left;
}
.textWS {
  white-space: nowrap;
}
.textLine {
  text-decoration: line-through;
}
.textB {
  word-break: break-word;
}
.textB-all {
  word-break: break-all;
}
.textB-keep {
  word-break: keep-all;
}
.line2-webkit {
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 4rem;
}
