@import url(https://storage.googleapis.com/static.thebulja.com/font/stylesheets/NotoSansKR-Hestia.css);
@import "./ClassSetColor.scss";
@import "./ClassSetText.scss";
@import "./ClassSetBox.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  height:100%;
  /* overflow-x:hidden; */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: #FFF;
  min-width: 360px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  width:100%;
  height:100%;
  font-size: 125%;
  /*overflow:hidden;*/
}

.worker {
  width: 100%;
}

#content-wrap {
  width:100%;
  height:100%;
  /* overflow-x:hidden; */
  /* overflow-y:scroll; 스크롤이 2개 생겨서 잠시 꺼둠 */
}

.sticky_m {
  position: fixed !important;
  top: -240px !important;
  width: 100%;
  right: 25% !important;
  z-index: 500;
}

.sticky {
  position: fixed !important;
  top: -100px !important;
  width: 100%;
  right: 25% !important;
  z-index: 500;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

@media screen and (min-width: 0\0) {
  /* IE 9, IE 10, IE 11 */
  :root,
  html {
    font-size: 10px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE 10, IE 11 */
  :root,
  html {
    font-size: 10px;
  }
}

.maintextbox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: left;
  color: #747474;
  border: 0.1rem solid #747474;
  border-top: hidden;
  border-left: hidden;
}
.flexbox {
  padding: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  border: 0.1rem solid #747474;
  background-color: #fff;
  border-bottom: hidden;
  border-right: hidden;
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  box-sizing: border-box;
}
.flexbox1 {
  padding: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  border: 0.1rem solid #747474;
  background-color: #fff;
  border-bottom: hidden;
  border-right: hidden;
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  box-sizing: border-box;
}
.flexbox2 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
}
.flexbox3 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  border-bottom: 0.1rem solid #747474;
  margin: 0 -0.3rem;
  padding: 0 0.3rem 0.2rem;
}
[class*="flexC"] {
  display: flex;
}
.fcolumn {
  flex-direction: column;
}
.flexCC {
  align-items: center;
}
.flexCCC {
  align-items: center;
  justify-content: center;
}
.posA {
  position: absolute;
}
.posR {
  position: relative;
}
.scrollHiddenY {
  overflow-y: scroll;
}
.scrollHiddenY::-webkit-scrollbar {
  display: none;
}
.overX {
  overflow: hidden;
}
